import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <g transform="translate(-419,-398) scale(3.6 3.6)">
          <path
            d="m 126.63399,127.3587 c 1.9558,0 2.5654,-0.5842 2.5654,-2.5781 v -6.1722 h -1.0922 v 6.1214 c 0,1.2827 -0.3556,1.6256 -1.5113,1.6256 -1.016,0 -1.3462,-0.2921 -1.3716,-1.3843 h -1.0922 c 0,1.8415 0.5715,2.3876 2.5019,2.3876 z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default IconLogo;
