import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(-410,-395) scale(3.6 3.6)">
        <path
          d="m 126.63399,127.3587 c 1.9558,0 2.5654,-0.5842 2.5654,-2.5781 v -6.1722 h -1.0922 v 6.1214 c 0,1.2827 -0.3556,1.6256 -1.5113,1.6256 -1.016,0 -1.3462,-0.2921 -1.3716,-1.3843 h -1.0922 c 0,1.8415 0.5715,2.3876 2.5019,2.3876 z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
