import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@components/icons';
import { socialMedia } from '@config';

const StyledFooter = styled.footer`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  height: auto;
  min-height: 70px;
  padding: 15px;
  text-align: center;
`;

const StyledSocialLinks = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    max-width: 270px;
    margin: 0 auto 10px;
    color: var(--light-slate);
  }

  ul {
    ${({ theme }) => theme.mixins.flexBetween};
    padding: 0;
    margin: 0;
    list-style: none;

    a {
      padding: 10px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

const TimeMachine = styled.div`
  div {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin: 0 120px 30px;
    position: relative;
  }

  @media (max-width: 480px) {
    div {
      margin: 0 100px 30px;
    }
  }

  img {
    border: 1px solid var(--green);
    border-radius: var(--border-radius);
    width: 240px;
  }

  .ogilvy-link {
    ${({ theme }) => theme.mixins.bigButton};
    border: none;
    position: absolute;
    bottom: 0;
  }

  .ogilvy-link:hover {
    transform: none;
    box-shadow: none;
  }
`;

const Footer = () => (
  <Fragment>
    <TimeMachine>
      <div>
        <a href="http://jpta.xyz/ogilvy-portfolio">
          <img
            alt="Time Machine"
            src="https://media.giphy.com/media/w6v1ZWiENPBbmJJ3HN/giphy.gif"
          />
        </a>
        <a className="ogilvy-link" href="/ogilvy-portfolio">
          See my 2015 portfolio
        </a>
      </div>
    </TimeMachine>
    <StyledFooter>
      <StyledSocialLinks>
        <ul>
          {socialMedia &&
            socialMedia.map(({ name, url }, i) => (
              <li key={i}>
                <a href={url} aria-label={name}>
                  <Icon name={name} />
                </a>
              </li>
            ))}
        </ul>
      </StyledSocialLinks>
    </StyledFooter>
  </Fragment>
);

Footer.propTypes = {
  githubInfo: PropTypes.object,
};

export default Footer;
