const TextRotator = (word1, word2, setThing) => {
  const alphabet = 'abcdefghijklm nopqrstuvwxyz4NZ'.split('');
  // make the words equal length by adding spaces
  const len = Math.max(word1.length, word2.length);
  while (word1.length < len) {
    word1 += ' ';
  }
  while (word2.length < len) {
    word2 += ' ';
  }
  // build the list
  const a = word1.split('');
  const b = word2.split('');
  const list = [];
  list.push(word1);
  while (a.join('') !== b.join('')) {
    for (let i = 0; i < len; i++) {
      if (a[i] === b[i]) {
        continue;
      } else {
        let aIndex = alphabet.indexOf(a[i]);
        const bIndex = alphabet.indexOf(b[i]);
        if (aIndex < bIndex) {
          if (bIndex - aIndex > 13) {
            aIndex--;
            if (aIndex < 0) {
              aIndex = alphabet.length - 1;
            }
          } else {
            aIndex++;
          }
        } else {
          if (aIndex - bIndex > 13) {
            aIndex++;
            if (aIndex === alphabet.length) {
              aIndex = 0;
            }
          } else {
            aIndex--;
          }
        }
        a[i] = alphabet[aIndex];
      }
    }
    list.push(a.join(''));
  }
  return new Promise(resolve => {
    let listIndex = 0;
    const intervalId = setInterval(() => {
      if (listIndex === list.length - 1) {
        clearInterval(intervalId);
        setTimeout(resolve, 1700);
      }
      setThing(list[listIndex]);
      listIndex++;
    }, 60);
  });
};

export default TextRotator;
